exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-freelancer-shopify-especialista-js": () => import("./../../../src/pages/freelancer-shopify-especialista.js" /* webpackChunkName: "component---src-pages-freelancer-shopify-especialista-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sobre-mim-js": () => import("./../../../src/pages/sobre-mim.js" /* webpackChunkName: "component---src-pages-sobre-mim-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

